import { TextField, Box, Dialog, IconButton, FormControl, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import theme from '../../theme';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SaveIcon from '@mui/icons-material/Save';
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
//import { Color } from '@tiptap/extension-color'
//import ListItem from '@tiptap/extension-list-item'
//import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import Collaboration from '@tiptap/extension-collaboration'
import * as Y from 'yjs'
import { WebsocketProvider } from 'y-websocket'

// TODO: Do we need to move this somewhere else?
const doc = new Y.Doc();

// TODO: Add all extensions we need
const extensions = [
  //Color.configure({ types: [TextStyle.name, ListItem.name] }),
  //TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  Collaboration.configure({
    document: doc, // Configure Y.Doc for collaboration
  }),
]

// TODO: Style and all buttons we need
const MenuBar = () => {
  const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  return (
    <div className="control-group">
      <div className="button-group">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          Italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          Strike
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleCode()
              .run()
          }
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          Code
        </button>
        <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          Clear marks
        </button>
        <button onClick={() => editor.chain().focus().clearNodes().run()}>
          Clear nodes
        </button>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          Paragraph
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          H1
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          H2
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          H3
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          H4
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          H5
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          H6
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          Bullet list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          Ordered list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          Code block
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          Blockquote
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          Horizontal rule
        </button>
        <button onClick={() => editor.chain().focus().setHardBreak().run()}>
          Hard break
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .undo()
              .run()
          }
        >
          Undo
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .redo()
              .run()
          }
        >
          Redo
        </button>
        <button
          onClick={() => editor.chain().focus().setColor('#958DF1').run()}
          className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
        >
          Purple
        </button>
      </div>
    </div>
  )
}

function EditDocument({ data }) {
  useEffect(() => {
    //TODO: Change url to use server url
    const provider = new WebsocketProvider('ws://localhost:1111/yws', '6945', doc)
  }, [])
  const [editOpen, setEditOpen] = useState(false);
  const [zoom, setZoom] = useState(false);

  return (
    <Box>
      <EditIcon onClick={() => setEditOpen(true)} />
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        fullScreen={zoom}
        fullWidth
        maxWidth="xl"
      >
        <ButtonBox>
          <StyledButton onClick={() => setZoom(!zoom)}>
            {zoom ? <ZoomOutIcon /> : <ZoomInIcon />}
          </StyledButton>
          <StyledButton onClick={() => setEditOpen(false)}>
            <CloseIcon />
          </StyledButton>
        </ButtonBox>
        <Box>
          <EditorProvider slotBefore={<MenuBar />} extensions={extensions}></EditorProvider>
        </Box>
      </Dialog>
    </Box>
  )
}

export default EditDocument

// Styling
const StyledButton = styled(IconButton)(() => ({
  color: theme.palette.black,
  background: `${theme.palette.stone}44`,
  transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
    background: `${theme.palette.stone}ff`
  }
}));

const StyledTextField = styled(TextField)(() => ({
  margin: "0 20px",
  minWidth: "80%",
  "& .MuiPaper-root": {
    width: "100%",
  }
}));

const ButtonBox = styled(Box)(() => ({
  padding: "30px 100px 30px 30px",
  display: "flex",
  alignItems: "center",
  gap: 30,
  justifyContent: "right"
}));

