import { Table, TableCell, TableBody, TableHead, TableContainer, Paper, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import theme from '../theme';

const VPs = ({data}) => {

    const vpsData = data.interaction.vps.value.vps;
    const texts = data.interaction.texts.value;

    const navigate = useNavigate();

    const handleRowClick = (path) => {
        navigate(path);
    };

    return (
        <div>
            <h1>{texts.title}</h1>

            <TableContainer component={Paper} sx={{margin:"30px 0"}}>
                <Table sx={{ minWidth: 650}}>
                    <TableHead sx={{ background: theme.palette.lightgreen }}>
                        <TableRow>
                            <TableCell>{texts.code}</TableCell>
                            <TableCell>{texts.name}</TableCell>
                            <TableCell align="right">{texts.members}</TableCell>
                            <TableCell align="right">{texts.waiting_members}</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {vpsData.map((vp) => (
                        <TableRow
                        key={vp.code}
                        hover
                        onClick={() => handleRowClick(vp.path)}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: "none" }}
                        >
                                <TableCell>{vp.code}</TableCell>
                                <TableCell>{vp.title}</TableCell>
                                <TableCell align="right">{vp.members_count}</TableCell>
                                <TableCell align="right">{vp.waiting_members_count}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default VPs  