import { Box, Button, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../theme";
import { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImageModal from "./ImageModal";

function BlogText({ data, image }) {
  const [fullHeight, setFullHeight] = useState(false);

  // Styling
  const Flexbox = styled(Stack)(() => ({
    flexDirection: "row",

    ".blog": {
      height: 200,
      
      "&__image": {
        display: "none",
        paddingTop: 20,
        maxHeight: 200,
        maxWidth: 300,
        objectFit: "contain",
        objectPosition: "top",

        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },

      "&__container": {
          width: "100%",
        "&__text": {
          width: "95%",
          maxHeight: "220px",
          transition: "max-height 200ms ease",
          overflow: "hidden",

          [theme.breakpoints.up("md")]: {
            width: "100%",
            paddingLeft: 30,
          },
          "&.fullHeight": {
            maxHeight: "100%",
          },
        },
        "&__button": {
          margin: "20px 0",

          [theme.breakpoints.up("md")]: {
            margin: "30px 100px 0",
          },
        },
      },
    },
  }));

  return (
    <Flexbox>
      {image && <ImageModal className="blog__image" image={image} />}
      <Box className="blog__container">
        <Typography
          className={
            !fullHeight
              ? "blog__container__text"
              : "blog__container__text fullHeight"
          }
          dangerouslySetInnerHTML={{ __html: data }}
        ></Typography>
        {data !== "" && data.length > 600 && (
          <Button
            className="blog__container__button"
            variant="contained"
            onClick={() => setFullHeight(!fullHeight)}
            startIcon={fullHeight ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          >
            {!fullHeight ? "weiterlesen" : "Text einklappen"}
          </Button>
        )}
      </Box>
    </Flexbox>
  );
}

export default BlogText;
