import { CalendarMonth, Description, Search as SearchIcon } from "@mui/icons-material"
import { Modal, Button, Box, InputBase, Stack,  Table, TableCell, TableBody, TablePagination, TableContainer, TableRow, TableHead } from "@mui/material"
import { useState, useRef, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { search } from "../helper/websocketHelper";
import { useNavigate } from 'react-router-dom'

const Search = () => {

  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSearchResults([]); //TODO!
  }

  const navigate = useNavigate();
  const handleRowClick = (path) => {
    navigate(path);
  };
  
  let searchTimeout = null;

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const sendSearchQuery = (input) => {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      search({term: input.target.value}).then(
      (result)=>{
        console.log(2, result)
        setSearchResults(result.results)
      })
    },
    300)
  }

  const getIcon = (type) => {
      if (type === "event") {
        return <CalendarMonth />
      } 
      if (type === "document") {
        return <Description />
      }
      return null
    }

  return (
    <>
      <Button onClick={handleOpen}><SearchIcon /></Button>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <InnerModal>
            <TableContainer>
              <Table stickyHeader sx={{ minWidth: 650}}>
                <TableHead>
                  <TableCell></TableCell>
                  <TableCell>
                    <StyledInputBase
                      inputRef={inputRef}
                      placeholder="Suche"
                      onChange={sendSearchQuery}
                    />
                  </TableCell>
                </TableHead>
                  {searchResults.length > 0 && (
                    <>
                      <TableBody sx={{overflow: "auto"}}>
                        {searchResults.map((e) => (
                            <TableRow
                              key={e._id}
                              hover
                              onClick={() => handleRowClick(e.path)}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: "none" }}
                            >
                              <TableCell>{getIcon(e.type)}</TableCell>
                              <TableCell>
                                {e.type === "event" && `${new Date(e.start_at).toLocaleDateString('de-DE', {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })} - `}
                                {e.title}
                              </TableCell>

                            </TableRow>
                        ))}
                      </TableBody>
                    </>
                  )}
              </Table>
            </TableContainer>
          </InnerModal>
        </Modal>
      </>
  )
}

export default Search

const InnerModal = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  maxHeight: "90vh",
  background: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  padding: "20px",
  overflow: "auto"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

