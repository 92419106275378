import { useState } from "react";
import { Typography, Box, Divider, IconButton, Snackbar } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import ImageModal from "./modules/ImageModal";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import CopyToClipboard from "react-copy-to-clipboard";
import { url } from "../helper/websocketHelper";
import EditDocument from "./modules/EditDocument";

function Document({ data }) {
  const document = data.interaction.document.value;

  // Snackbar (confirm copy to clipboard)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <MainContent>
        <StyledHeadline variant="h3">{document.title}</StyledHeadline>
        <SublineDateAndAuthor>
          {document.topic && (
            <TopicText sx={{ paddingLeft: "30px" }}>
              Beitragsthema: {document.topic}
            </TopicText>
          )}
          {document.created_at && (
            new Date(document.created_at).toLocaleDateString('de-DE', {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          )}
          {document.created_by && (
            <Typography>geschrieben von: {document.created_by}</Typography>
          )}
          <Box>
            <IconButton>
              <EditDocument data={document.body} />
            </IconButton>
            {/*  TODO: Link stimmt so nicht */}
            <CopyToClipboard text={window.location.href}>
              <IconButton onClick={() => setOpenSnackbar(true)}>
                <LinkIcon />
              </IconButton>
            </CopyToClipboard>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleClose}
              message="Link in die Zwischenablage kopiert"
              action=""
            />
          </Box>
        </SublineDateAndAuthor>
        <ContentBox>
          {document.body &&
            <BlogText
              dangerouslySetInnerHTML={{ __html: document.body }}
            ></BlogText>
          }
          <Aside>
            {document.images.length > 0 && (
              <>
                <Typography variant="h5" sx={{ margin: "20px" }}>
                  Bilder
                </Typography>
                {document.images.map((image, index) => {
                  // TODO: image link muss hier noch korrigiert werden
                  return (
                    <ImageModal
                      key={index}
                      image={image}
                      index={index}
                      stretch
                    />
                  );
                })}
              </>
            )}
            {document.images.length > 0 && document.files.length > 0 && (
              <Divider sx={{ color: theme.palette.darkgreen }} />
            )}
            {document.files.length > 0 && (
              <>
                <Typography variant="h5" sx={{ margin: "20px" }}>
                  Dateien
                </Typography>
                {document.files.map((file, index) => {
                  return (
                    <FileDownload key={index} href={`${url}${file.path}`}>
                      <DownloadIcon />
                      <p>
                        {file.name}
                      </p>
                    </FileDownload>
                  );
                })}
              </>
            )}
          </Aside>
        </ContentBox>
      </MainContent>
    </>
  );
}

export default Document;

// Styling

const MainContent = styled(Box)(() => ({
  marginBottom: 80,
  [theme.breakpoints.up("md")]: {
    marginBottom: 100,
  },
}));

const StyledHeadline = styled(Typography)(() => ({
  borderLeft: `${theme.palette.yellow} 20px inset`,
  borderTop: `${theme.palette.white} 10px outset`,
  borderBottom: `${theme.palette.white} 10px inset`,
  background: `${theme.palette.yellow}44`,
  padding: 20,
  marginLeft: 0,
  width: "85%",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    marginLeft: "-20px",
    paddingLeft: 30,
  },
}));

const TopicText = styled(Typography)(() => ({
  padding: "20px 0 0 0",
  [theme.breakpoints.up("md")]: {
    padding: "0 0 0 20px",
  },
}));

const SublineDateAndAuthor = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 30px",
  margin: "20px 0 20px -16px",
  width: "90%",
  borderBottom: `${theme.palette.lightgreen} 3px solid`,
  [theme.breakpoints.up("md")]: {
    margin: "10px auto",
  },
}));

const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const BlogText = styled(Box)(() => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "0 20px 0 10px",
  textAlign: "left",
  fontFamily: "lora",
  fontSize: 19,

  [theme.breakpoints.up("md")]: {
    padding: 30,
    width: "70%",
  },

  '& table tr': {
    position: "relative",

    '&::before': {
      content: '""',
      position: "absolute",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      borderBottom: "1px solid black",
    }
  },

  '& td': {
    padding: "10px"
  }
}));

const FileDownload = styled("a")(() => ({
  color: theme.palette.black,
  display: "flex",
  alignItems: "center",
  gap: 20,
  background: theme.palette.lightgreen,
  padding: 20,
  marginBottom: 20,
  textDecorationColor: "transparent",
  transition: "text-decoration-color 350ms ease-in-out",
  
  "& p": {
    wordBreak: "break-all",
    overflowWrap: "break-word",
    textWrap: "wrap",
  }, 
  "& svg": {
    transform: "scale(1)",
    transition: "transform 350ms ease",
  },
  "&:hover": {
    transition: "text-decoration-color 350ms ease-in-out",
    textDecorationColor: theme.palette.black,
    "& svg": {
      transform: "scale(1.5)",
      transition: "transform 350ms ease",
    },
  },
}));

const Aside = styled(Box)(() => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "0 25px 0 10px",
  [theme.breakpoints.up("md")]: {
    width: "30%",
    padding: "0 30px",
  },
}));
